export interface INavigationRoute {
  name: string
  displayName: string
  meta: { icon: string }
  children?: INavigationRoute[]
  badge?: string
}

export default {
  root: {
    name: '/',
    displayName: '',
  },
  routes: [
    {
      name: 'dashboard-data',
      displayName: 'Главная',
      meta: {
        icon: 'mso-home',
      },
      children: [
        {
          name: 'dashboard',
          displayName: 'Основные настройки',
        },
        {
          name: 'banners',
          displayName: 'Баннеры',
        },
      ],
    },
    {
      name: 'services-data',
      displayName: 'Услуги',
      meta: {
        icon: 'mso-dashboard',
      },
      children: [
        {
          name: 'services',
          displayName: 'Список услуг',
        },
        {
          name: 'breed-services',
          displayName: 'Разводящая страница',
        },
      ],
    },
    {
      name: 'cases-data',
      displayName: 'Кейсы',
      meta: {
        icon: 'mso-note_stack',
      },
      children: [
        {
          name: 'cases',
          displayName: 'Список кейсов',
        },
        {
          name: 'portfolio',
          displayName: 'Разводящая страница',
        },
        {
          name: 'become-client',
          displayName: 'Баннер "Стать клиентом"',
        },
      ],
    },
    {
      name: 'awards',
      displayName: 'Награды',
      meta: {
        icon: 'mso-emoji_events',
      },
    },
    {
      name: 'reviews',
      displayName: 'Отзывы',
      meta: {
        icon: 'mso-reviews',
      },
    },
    {
      name: 'contacts',
      displayName: 'Контакты',
      meta: {
        icon: 'mso-phone_in_talk',
      },
    },
    {
      name: 'guides',
      displayName: 'Справочники',
      meta: {
        icon: 'mso-terminal',
      },
      children: [
        {
          name: 'pages',
          displayName: 'SEO Страницы',
        },
        {
          name: 'ready-solutions',
          displayName: 'Готовые решения',
        },
        {
          name: 'tech',
          displayName: 'Технологии',
        },
        {
          name: 'partners',
          displayName: 'Партнеры',
        },
        {
          name: 'tags',
          displayName: 'Теги',
        },
        {
          name: 'industries',
          displayName: 'Отрасли',
        },
      ],
    },
    {
      name: 'feedbacks',
      displayName: 'Входящие обращения',
      meta: {
        icon: 'mso-chat',
      },
    },

    {
      name: 'users',
      displayName: 'Пользователи',
      meta: {
        icon: 'mso-group',
      },
    },
  ] as INavigationRoute[],
}
